<template>
  <gc-dialog
    title="同步C端用户信息"
    :show.sync="visible"
    :ok-text="'确 定'"
    @ok="handleOk"
    @cancel="visible = false"
    @close="visible = false"
    :append-to-body="true"
  >
    <el-form ref="form" :model="form">
      <el-form-item label="同步方式">
        <el-radio-group v-model="form.uatype">
          <el-radio :label="0">增量</el-radio>
          <el-radio :label="1">全量</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </gc-dialog>
</template>

<script>
import { apiUaSync } from "@/api/account";

export default {
  name: "DialogUasync",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        uatype: 0,
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
  },
  watch: {
    show(val) {
      if (!val) {
        this.form = this.$options.data().form;
        this.$refs.form?.resetFields();
      }
    },
  },
  methods: {
    handleOk() {
      this.$toast("正在同步C端用户信息，请稍后...");
      apiUaSync({
        uatype: this.form.uatype,
      })
        .then(() => {
          this.$message.success("同步成功");
        })
        .finally(() => {
          this.$toast.clear();
          this.visible = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-form {
    margin-top: 20px;
  }
}
</style>
